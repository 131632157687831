export const movieData = [
    {
        title : "Close Encounters of the Third Kind",
        releaseYear : "1977",
        pg : "PG",
        duration : "2h18",
        director : "Steven Spielberg",
        stars : "Richard Dreyfuss · François Truffaut · Teri Garr",
        synopsis : "Roy Neary, an Indiana electric lineman, finds his quiet life turned upside down after a close encounter with a UFO, spurring him to an obsessed cross-country quest for answers as a momentous event approaches.",
        img : "close-encounters-of-the-third-kind.jpg",
        ufoImg : "UFO-Light.png",
        imdb : "https://www.imdb.com/title/tt0075860/",
        shape : "light"
    },
    {
        title : "Independance Day",
        releaseYear : "1996",
        pg : "PG-13",
        duration : "2h25",
        director : "Roland Emmerich",
        stars : "Will Smith · Bill Pullman · Jeff Goldblum",
        synopsis : "The aliens are coming and their goal is to invade and destroy Earth. Fighting superior technology, mankind's best weapon is the will to survive.",
        img : "independance-day.jpg",
        ufoImg : "UFO-Circle.png",
        imdb : "https://www.imdb.com/title/tt0116629/",
        shape : "circle"
    },
    {
        title : "Star Wars",
        releaseYear : "1977",
        pg : "PG",
        duration : "2h01",
        director : "George Lucas",
        stars : "Mark Hamill · Harrison Ford · Carrie Fisher",
        synopsis : "Luke Skywalker joins forces with a Jedi Knight, a cocky pilot, a Wookiee and two droids to save the galaxy from the Empire's world-destroying battle station, while also attempting to rescue Princess Leia from the mysterious Darth Vader.",
        img : "star-wars.jpg",
        ufoImg : "UFO-Triangle.png",
        imdb : "https://www.imdb.com/title/tt0076759/",
        shape : "triangle"
    },
    {
        title : "Alien",
        releaseYear : "1979",
        pg : "R",
        duration : "1h57",
        director : "Ridley Scott",
        stars : "Sigourney Weaver · Tom Skeritt · John Hurt",
        synopsis : "The crew of a commercial spacecraft encounter a deadly lifeform after investigating an unknown transmission.",
        img : "alien.jpg",
        ufoImg : "UFO-Unknown.png",
        imdb : "https://www.imdb.com/title/tt0078748/",
        shape : "unknown"
    },
    {
        title : "Sunshine",
        releaseYear : "2007",
        pg : "R",
        duration : "1h47",
        director : "Danny Boyle",
        stars : "Cillian Murphy · Rose Byrne · Chris Evans",
        synopsis : "A team of international astronauts are sent on a dangerous mission to reignite the dying Sun with a nuclear fission bomb in 2057.",
        img : "sunshine.jpg",
        ufoImg : "UFO-Fireball.png",
        imdb : "https://www.imdb.com/title/tt0448134/",
        shape : "fireball"
    }
]